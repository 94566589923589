import Link from 'next/link';
import MetaHead from '@/components/util/MetaHead';
import MaintenanceLayout from '@/components/layouts/MaintenanceLayout/MaintenanceLayout';

const Custom404 = (): JSX.Element => {
  return (
    <>
      <MetaHead
        title="404"
        description="Oops. We're going to go out on a limb and guess this is not the page you hoped for."
        pageURL="https://gettactic.com/404"
        canonicalURL="https://gettactic.com/404"
      />
      <MaintenanceLayout>
        <div className="relative h-screen bg-orange-100">
          <div className="relative bg-orange-100 pt-6 pb-20">
            <div className="absolute inset-0">
              <div className="h-1/3 bg-orange-100 sm:h-2/3" />
            </div>
            <div className="relative mx-auto max-w-7xl text-center text-secondary">
              <div className="h-full">
                <h1 className="mt-16 text-6xl">404</h1>
                <h2 className="mt-2 text-4xl">Page Not Found</h2>
                <p className="pt-8">Oops. Not sure how you got here? </p>
                <p className="pt-2">
                  <Link href="/dashboard">
                    <span className="underline hover:cursor-pointer">
                      Go back to the dashboard
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </MaintenanceLayout>
    </>
  );
};

export default Custom404;
